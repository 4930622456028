import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useContext,
  createRef,
  useCallback,
  useMemo,
} from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import _map from "lodash/map"
import _head from "lodash/head"
import _get from "lodash/get"
import _noop from "lodash/noop"
import _isEmpty from "lodash/isEmpty"
import useRazorpay from "react-razorpay"

import "../styles/new-space.scss"
import SEOHeader from "../components/seo-header"
import SimpleMap from "../components/simplemap"
import scrollTo from "gatsby-plugin-smoothscroll"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import EnquiryForm from "../components/enquiry"
import NearBy from "../components/nearby"
import VizSensor from "react-visibility-sensor"
import moment from "moment"
import { nodeName } from "jquery"
import Axios from "axios"
import "react-datepicker/dist/react-datepicker.css"
import DateItem from "../components/DateItem"
import DeskBooking from "../components/DeskBooking"
import ApplyDiscount from "../components/applyDiscount"
import {
  addEventDetailsToThePayload,
  getBookingConfirmationStatus,
  getSpaceAvailabilityPayload,
  getSpaceAvailabilityPayloadForMeetingSpaces,
  getSpacePriceDetails,
  isInstantlyBookableAwfis,
  isInstantlyBookableInnov8,
  isOfficeSpace,
} from "../utils/common"
import {
  checkBookingAvailability,
  createBooking,
  createRazorpayOrder,
  redeemToConfrimBooking,
  updatePaymentStatus,
} from "../api/common"
import MeetingRoomBooking from "../components/MeetingRoomBooking"
import AuthContext from "../contexts/AuthContext"
import lottie from "lottie-web"
import confettiAnimation from "../animations/confetti.json"
import Loader from "../components/Loader"
import BookingCard from "../components/BookingCard"
import BookingCardSmall from "../components/BookingsCardSmall"
import Bookings from "./bookings"
import { COLORS } from "../constants/colors"

const SPACE_DATA = {
  address: {
    city: "Bengaluru",
    country: "India",
    landmark: "Near Mahalakshmi Metro Station ",
    locality: "Mahalakshmi Layout ",
    street: "#7, 2nd Floor, 1st Main Road",
    zipcode: "560086",
  },
  app: "spaceowner",
  bankAccountNumber: "029805003054",
  bankname_branch: "ICICI Bank",
  bookingcount: 13,
  calendarId: "",
  calendarURL: "N/A",
  city: "Bengaluru",
  dateAdded: 1557081000000,
  dayPassAvailable: true,
  exclusiveOffer:
    "Paid Street Parking: Car - 100+GST, BIke - 80+GST for 8 hours. Tea/coffee Paid 20/25. Cafeteria operational hours are 9 am-8 pm",
  facilitiesList: [
    "Power Backup",
    "Rest Rooms",
    "Outside Food Allowed",
    "Whiteboard",
    "WiFi Backup",
    "Hi Speed WiFi",
    "AC",
    "Pantry",
    "Television",
    "Paid Parking",
    "Disinfected Daily",
    "Metro Nearby",
  ],
  gofRevenueShare: "20",
  gofloatersSpaceName: "GoSpace 2027",
  gstRegistered: true,
  gstin: "29AAECI2876H1Z8",
  hasCovidSafeBadge: true,
  hourPassAvailable: true,
  ifscCode: "ICIC0000298",
  isApproved: true,
  islat: true,
  location: [13.0084274, 77.548536],
  managerEmail: "deskhubblr@gmail.com",
  managerName: "Kalyan",
  managerphone: "8050837448",
  minDuration: 2,
  minHeadCount: 7,
  mobile: "9743056173",
  noiseLevel: "Low",
  online: true,
  operationTime: "Mon-Sun  : 09:00 AM to 08:00 PM",
  operationTiming: {
    days: {
      friday: {
        from: "09:00",
        to: "20:00",
        useNow: true,
      },
      monday: {
        from: "09:00",
        to: "20:00",
        useNow: true,
      },
      saturday: {
        from: "09:00",
        to: "20:00",
        useNow: true,
      },
      sunday: {
        from: "09:00",
        to: "20:00",
        useNow: true,
      },
      thursday: {
        from: "09:00",
        to: "20:00",
        useNow: true,
      },
      tuesday: {
        from: "09:00",
        to: "20:00",
        useNow: true,
      },
      wednesday: {
        from: "09:00",
        to: "20:00",
        useNow: true,
      },
    },
    displayName: "Monday-Sunday  : 09:00 AM to 08:00 PM",
  },
  operatorName: "Desk Hub",
  originalName: "Desk Hub ",
  ownerEmail: "deskhubblr@gmail.com",
  ownerId: "bdGLrWPWMLTsFhkeUu3HCbNJJ2y1",
  ownerName: "Srinath",
  photos: [
    "https://cdn.app.gofloaters.com/images%2F1571462402465_8%20seater%20Conference%20room%20at%20Mahalakshmi%20Layout%201.JPG?alt=media&token=7f6471c7-5f29-48ac-8209-702aeb459f34",
    "https://cdn.app.gofloaters.com/images%2F1571462402470_8%20seater%20Conference%20room%20at%20Mahalakshmi%20Layout%202.JPG?alt=media&token=7cbb55df-845c-41b3-b9be-27ea183afea6",
    "https://cdn.app.gofloaters.com/images%2F1571462402473_8%20seater%20Conference%20room%20at%20Mahalakshmi%20Layout%204.JPG?alt=media&token=9a619417-b45d-42e5-bfe5-6be859b1c733",
    "https://cdn.app.gofloaters.com/images%2F1571462402471_8%20seater%20Conference%20room%20at%20Mahalakshmi%20Layout%203.JPG?alt=media&token=b9135f66-3454-41a1-8924-dba90126359c",
    "https://cdn.app.gofloaters.com/images%2F1571462402474_8%20seater%20Conference%20room%20at%20Mahalakshmi%20Layout%205.JPG?alt=media&token=0a51998b-cb78-4519-891b-3a9ab5f1218f",
  ],
  priceperday: "4250",
  priceperhr: "650",
  purposes: ["one to one Meetings", "Team Meetings", "Interviews"],
  purposesList: ["One to One Meetings", "Team Meetings", "Interviews"],
  registeredCompanyName: "Infocube ",
  requestBy: "spacepartner",
  seatsAvailable: "7",
  spaceDesc:
    "Professional ambience and neat interiors characterize this 8-seater conference room. It also comes with the amenities needed to run a meeting, such as a whiteboard and Wi-Fi. Pick this spot to hold a formal meeting or discussion.",
  spaceDisplayName: "7 Seater Meeting Room",
  spaceId: "-LeAvIkyX5uGK7_SJwam",
  spaceName: "7 Seater Meeting Room | Mahalakshmi Layout",
  spaceSubType: ["Meeting Space"],
  spaceType: "Conference Room",
  worqFlexiLastSyncTime: 1660728937442,
  worqFlexiRecordID: "3852549000021493308",
  zohoLastSyncTime: 1631820897572,
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const BOOKING_DATA = [
  {
    status: "success",
    booking: {
      bookingkey: "-Na2hFHWC-BZ2fbVHDPc",
      bookingid: "GF0723037015",
      lastUpdatedById: "287kKs4SCcUqK4zNrIoZmMzEPD52",
      lastUpdatedAt: 1690131825840,
      lastUpdatedByEmail: "marketing@gofloaters.com",
      custId: "287kKs4SCcUqK4zNrIoZmMzEPD52",
      customerName: "Test GoFloaters",
      customerPhoneNumber: "9087335533",
      customerEmail: "marketing@gofloaters.com",
      userLevel: "Blue",
      spaceId: "-N-CGjeH1xuqomuDPtPV",
      spaceName: "4 Seater Meeting Room | Guindy",
      originalName: "AWFIS Spero Primus",
      gofloatersSpaceName: "GoSpace 1440",
      spaceDisplayName: "4 Seater Meeting Room",
      spaceType: "Conference Room",
      spacePhoto:
        "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o/images%2F4%20Seater%20Meeting%20Room%20%7C%20Guindy_1649490616721?alt=media&token=097b5e07-472d-4f4a-a997-3b4723414ecc",
      spaceaddress: {
        city: "Chennai",
        country: "India",
        landmark: "Primus Building",
        locality: "Guindy",
        street:
          "Door No. SP – 7A, Guindy Industrial Estate, SIDCO Industrial Estate",
        zipcode: "600032",
      },
      spacecoords: [13.0146285, 80.2016883],
      gofRevenueShare: "10",
      priceperhr: "800",
      spaceOwnerEmail: "shyam@gofloaters.com",
      gstRegistered: true,
      reqDate: 1690131825840,
      reqTime: 1690131825840,
      duration: 1,
      headCount: "4",
      bookingUsageFee: 800,
      spaceUsageFee: 800,
      bookingdate: 1690169400000,
      startTime: 1690169400000,
      status: "Requested",
      bookingFee: 0,
      redeemedPoints: 0,
      gateway: "",
      paymentMade: 0,
      gstFee: 0,
      foodCreditsAvailable: 0,
      paymentRefNumber: "",
      bookingReason: "",
      bookingOwner: "Shyam@gofloaters.com",
      bookingApp: "ReactNative",
    },
  },
]

const Booking = props => {
  const params = new URLSearchParams(props.location.search)
  const spaceid = params.get("spaceid") // bar
  const eventId = params.get("eventId")
  const [spaceDetails, setSpaceDetails] = useState(null)
  const [gettingSpaceDetails, setGettingSpaceDetails] = useState(true)
  const [booking, setBooking] = useState(false)
  const [currentTab, setcurrentTab] = useState("overview")
  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })
  const [showPhotos, setshowPhotos] = useState(false)
  const [FAQ, setFAQ] = useState(0)
  const [bookingDetails, setBookingDetails] = useState(null)
  const [loadAnimation, setLoadAnimation] = useState(false)
  const [bookingInProgress, setBookingInProgress] = useState(false)
  const [discountAmount, setdiscountAmount] = useState(0)
  const [discountCode, setDiscountCode] = useState(null)
  const deskBookingRef = useRef(null)
  const meetingRoomBookingRef = useRef(null)
  const [reviewTab, setReviewTab] = useState("GoFloaters")
  const { user } = useContext(AuthContext)
  const [Razorpay] = useRazorpay()

  useEffect(() => {
    setLoadAnimation(true)
  }, [bookingDetails])

  let animationContainer = createRef()
  useEffect(() => {
    let anim = { destroy: _noop }
    // console.log({ loadAnimation, animationContainer })
    if (loadAnimation && animationContainer?.current) {
      anim = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: confettiAnimation,
      })
      setLoadAnimation(false)
    }
    return () => {
      animationContainer.current = animationContainer.current || {}
      anim.destroy(animationContainer.current)
    } // optional clean up for unmounting
  }, [animationContainer, loadAnimation])

  useEffect(() => {
    setGettingSpaceDetails(true)
    Axios.post(`https://gofloaters.firebaseapp.com/admin/getspace`, {
      id: spaceid,
    }).then(res => {
      let responsevalue = res.data
      console.log({ responsevalue })
      setSpaceDetails(responsevalue)
      setGettingSpaceDetails(false)
    })
  }, [])
  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY })
    }

    window.addEventListener("scroll", updatePosition)
    updatePosition()

    return () => window.removeEventListener("scroll", updatePosition)
  }, [])
  const handleDiscount = (discountPercentage, discountCode) => {
    if (discountPercentage) {
      setdiscountAmount(Number(discountPercentage))
      setDiscountCode(discountCode)
    } else {
      setdiscountAmount(0)
    }
  }
  useEffect(() => {
    // Here you can perform any side effects that depend on discountAmount
    // For example, you might want to update some state or perform some action
    // based on the value of discountAmount
    console.log("Discount amount changed:", discountAmount)
  }, [discountAmount])
  const openRazorpayForPayment = useCallback(
    async (orderId, booking, priceToPay) => {
      const totalPayableAmount = _get(priceToPay, "totalPrice")
      console.log({ priceToPay, totalPayableAmount })
      const options = {
        key: "rzp_live_L4jnEFvzps4krf",
        amount: totalPayableAmount * 100,
        currency: "INR",
        name: "Gofloaters",
        description: "Payment for the space booking",
        image: "https://assets.gofloaters.com/logo.png",
        order_id: orderId,
        handler: async res => {
          console.log({ spaceDetails })
          const updatePaymentResponse = await updatePaymentStatus({
            paymentRefNumber: _get(res, "razorpay_payment_id"),
            uid: _get(user, "uid"),
            spaceType: _get(spaceDetails, "spaceType"),
            spaceUsageFee: _get(priceToPay, "spaceUsageFee"),
            gstFee: _get(priceToPay, "gst"),
            redeemedPoints: _get(user, "floatingpoints"),
            id: _get(booking, "bookingkey"),
          })
          setBookingDetails(updatePaymentResponse)
        },
        prefill: {
          name: _get(user, "displayName"),
          email: _get(user, "email"),
          contact: _get(user, "phoneNumber"),
        },
        notes: {
          address: "Gofloaters",
        },
        theme: {
          color: "#2798B5",
        },
      }

      const rzpay = new Razorpay(options)
      rzpay.on("payment.failed", function (response) {
        alert(response.error.description)
        console.log({ response })
      })

      rzpay.open()
    },
    [Razorpay, user, spaceDetails]
  )

  const getPriceToPay = () => {
    let price = deskBookingRef?.current?.getPriceToCollect()
    if (!isOfficeSpace(spaceDetails)) {
      price = meetingRoomBookingRef?.current.getPriceToCollect()
    }
    return price
  }
  const TimeFormat = time => {
    const t = new Date(time)
    const formatted = moment(t).format("MMMM d, YYYY")
    return formatted
  }

  const onBookNowPress = async () => {
    setBookingInProgress(true)
    if (isOfficeSpace(spaceDetails)) {
      const { spaceId } = spaceDetails
      const bookingPayload = deskBookingRef?.current.getBookingPayload()
      console.log({ bookingPayload })
      if (bookingPayload) {
        const spaceAvailabilityPayload = getSpaceAvailabilityPayload(
          spaceId,
          bookingPayload
        )
        console.log({ deskSpaceAvailabiloityPayload: spaceAvailabilityPayload })
        try {
          const response = await checkBookingAvailability(
            spaceAvailabilityPayload
          )

          console.log({ bookingPayload, spaceAvailabilityPayload })
          if (response) {
            bookingPayload.discountCode = discountCode
            try {
              const bookingResponse = await createBooking(
                addEventDetailsToThePayload(bookingPayload, eventId)
              )
              const { booking, proceedToPayment } = bookingResponse
              console.log("bookingResponse", bookingResponse)
              const price = getPriceToPay()
              if (proceedToPayment) {
                if (_get(price, "totalPrice") == 0) {
                  const response = await redeemToConfrimBooking(booking, user)
                  if (!_isEmpty(response)) {
                    setBookingDetails(_get(response, "booking"))
                    console.log("Credits redeemed successfully.")
                    alert("Credits redeemed successfully.")
                    // toast.show('Credits redeemed successfully.');
                  }
                } else {
                  const razorpayResponse = await createRazorpayOrder(
                    _get(booking, "bookingkey")
                  )
                  console.log({ razorpayResponse })
                  const razorpayPaymentDetails = await openRazorpayForPayment(
                    _get(razorpayResponse, "orderId"),
                    booking,
                    getPriceToPay()
                  )
                }
              } else {
                setBookingDetails(booking)
              }
              // ----
              // navigation.navigate(ROUTES.BOOKINGS);
            } catch (error) {
              console.log({ error })
              // toast.show("Error booking space. Please try again.")
              alert("Error booking space. Please try again.")
            }
          } else {
            // toast.show("Space not available for selected date and time")
            alert("Space not available for selected date and time")
          }
        } catch {
          // toast.show("Space not available for selected date and time")
          alert("Space not available for selected date and time")
        } finally {
          setBookingInProgress(false)
        }
      }
      setBookingInProgress(false)
    } else {
      const bookingPayload = meetingRoomBookingRef?.current.getBookingPayload()
      // setBooking(false);
      console.log({ bookingPayload })
      if (bookingPayload) {
        try {
          let allowBooking = true
          const { spaceId } = spaceDetails
          if (
            isInstantlyBookableAwfis(spaceDetails) ||
            isInstantlyBookableInnov8(spaceDetails)
          ) {
            const spaceAvailabilityPayload = getSpaceAvailabilityPayloadForMeetingSpaces(
              spaceId,
              bookingPayload
            )
            allowBooking = await checkBookingAvailability(
              spaceAvailabilityPayload
            )
          }
          if (allowBooking) {
            try {
              bookingPayload.discountCode = discountCode
              const bookingResponse = await createBooking(
                addEventDetailsToThePayload(bookingPayload, eventId)
              )
              console.log({ bookingResponse })
              const { booking, proceedToPayment } = bookingResponse
              const price = getPriceToPay()

              if (proceedToPayment) {
                if (_get(price, "totalPrice") == 0) {
                  const response = await redeemToConfrimBooking(booking, user)
                  if (!_isEmpty(response)) {
                    console.log("Credits redeemed successfully.")
                    setBookingDetails(_get(response, "booking"))
                    alert("Credits redeemed successfully.")
                    setBookingInProgress(false)
                    // toast.show('Credits redeemed successfully.');
                  }
                } else {
                  const razorpayResponse = await createRazorpayOrder(
                    _get(booking, "bookingkey")
                  )
                  console.log({ razorpayResponse })
                  const razorpayPaymentDetails = await openRazorpayForPayment(
                    _get(razorpayResponse, "orderId"),
                    booking,
                    getPriceToPay()
                  )
                }
              } else {
                setBookingDetails(booking)
              }

              // console.log({ bookingResponse })
              // setBookingDetails(bookingResponse)
              // navigation.navigate(ROUTES.BOOKINGS);
            } catch {
              // toast.show("Error booking space. Please try again.")
              alert("Error booking space. Please try again.")
            }
          } else {
            // toast.show("Space not available for selected date and time")
            alert("Space not available for selected date and time")
          }
        } catch {
          // toast.show("Space not available for selected date and time")
          alert("Space not available for selected date and time")
        } finally {
          setBookingInProgress(false)
        }
      }
      setBookingInProgress(false)
    }
  }

  if (gettingSpaceDetails) return null

  console.log({ spaceDetails, user })

  function tConv24(time24) {
    var ts = time24
    var H = +ts.substr(0, 2)
    var h = H % 12 || 12
    h = h < 10 ? "0" + h : h // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM"
    ts = h + ts.substr(2, 3) + ampm
    return ts
  }
  var today = new Date()
  var day = today.getDay()

  var daylist = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]
  var todayDay = daylist[day]

  let operationTime = "Good"

  if (_get(spaceDetails, "operationTime") !== "") {
    operationTime = _get(spaceDetails, "operationTime")
      .split(", ")
      .map((operationTime, index) => (
        <div className="col-md-4  ">
          <div className="OperationTime row aligner" key={index}>
            <div className="col-2 text-center">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                {" "}
                <path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
              </svg>{" "}
            </div>
            <div className="col-10">
              <p className="OperationTimeText">
                <span>{operationTime.split(":")[0]}</span>
                <br></br>
                {operationTime.split(" : ")[1]}
              </p>
            </div>
          </div>
        </div>
      ))
  }

  let SpaceSuitedFor = ""
  if (_get(spaceDetails, "purposesList")) {
    SpaceSuitedFor = _get(spaceDetails, "purposesList").map(
      (purpose, index) => (
        <span className="SpaceSuited" key={index}>
          {purpose}
        </span>
      )
    )
  }

  const feedbackArray = Object.values(_get(spaceDetails, "feedback", {}))

  const feedbackList = feedbackArray
    .sort((a, b) => (a.feedbackDate < b.feedbackDate ? 1 : -1))
    .map((dataDrop, index) => {
      if (!dataDrop.customerName) {
        return null // Skip if customerName is missing
      }

      return (
        <div className="ratingCard" key={index}>
          <div className="row">
            <div className="col-md-12">
              <p className="customerName">{dataDrop.customerName}</p>
            </div>
            <div className="col-md-12">
              <p>{dataDrop.commentForSpaces}</p>
            </div>
            <div className="col-md-9 col-6">
              <div>
                <span
                  className={
                    "stars-container stars-" +
                    Number(20) * Number(dataDrop.rating)
                  }
                >
                  ★★★★★
                </span>
              </div>
            </div>
            <div className="col-md-3 col-6 text-right">
              <p style={{ fontSize: "12px" }}>
                {TimeFormat(dataDrop.feedbackDate)}
              </p>
            </div>
          </div>
        </div>
      )
    })

  function timeCal(todayDay) {
    const operationTiming = _get(spaceDetails, "operationTiming")
    const operationDay = operationTiming.days
    switch (todayDay) {
      case "Monday":
        if (operationDay.monday.holiday) return "Holiday"
        else {
          return (
            tConv24(operationDay.monday.from) +
            " - " +
            tConv24(operationDay.monday.to)
          )
        }
      case "Tuesday":
        if (operationDay.tuesday.holiday) return "Holiday"
        else {
          return (
            tConv24(operationDay.tuesday.from) +
            " - " +
            tConv24(operationDay.tuesday.to)
          )
        }
      case "Wednesday":
        if (operationDay.wednesday.holiday) return "Holiday"
        else {
          return (
            tConv24(operationDay.wednesday.from) +
            " - " +
            tConv24(operationDay.wednesday.to)
          )
        }
      case "Thursday":
        if (operationDay.thursday.holiday) return "Holiday"
        else {
          return (
            tConv24(operationDay.thursday.from) +
            " - " +
            tConv24(operationDay.thursday.to)
          )
        }
      case "Friday":
        if (operationDay.friday.holiday) return "Holiday"
        else {
          return (
            tConv24(operationDay.friday.from) +
            " - " +
            tConv24(operationDay.friday.to)
          )
        }
      case "Saturday":
        if (operationDay.saturday.holiday) return "Holiday"
        else {
          return (
            tConv24(operationDay.saturday.from) +
            " - " +
            tConv24(operationDay.saturday.to)
          )
        }
      case "Sunday":
        if (operationDay.sunday.holiday) return "Holiday"
        else {
          return (
            tConv24(operationDay.sunday.from) +
            " - " +
            tConv24(operationDay.sunday.to)
          )
        }
      default:
        return ""
    }
  }

  const getContentView = () => {
    if (!bookingDetails)
      return (
        <div>
          <h1 className="coworkingTitle">
            {_get(spaceDetails, "spaceDisplayName")} in{" "}
            {_get(spaceDetails, "address.locality")},{" "}
            {_get(spaceDetails, "address.city")}
          </h1>
          <h2 className="coworkingLocationTitle">
            {_get(spaceDetails, "originalName")}
          </h2>

          {getSpacePriceDetails(spaceDetails)}
          <div className="row">
            <div className="col-md-6">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                autoPlay={false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                containerClass="carousel-container "
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={"tablet"}
                dotListClass="custom-dot-list-style"
              >
                {_map(spaceDetails?.photos ?? [], photo => {
                  return (
                    <div className="CoworkingimageBox">
                      <img
                        src={photo.replace(
                          "https://firebasestorage.googleapis.com/v0/b/gofloaters.appspot.com/o",
                          "https://cdn.app.gofloaters.com"
                        )}
                        className="d-block w-100 img-responsive"
                        alt={
                          spaceDetails?.spaceGFName +
                          " " +
                          spaceDetails?.spaceDisplayName
                        }
                      ></img>
                    </div>
                  )
                })}
              </Carousel>
              {_get(spaceDetails, "meetingRoomName") ? (
                <p style={{ fontSize: "18px", paddingTop: "5px" }}>
                  Room Name: <b>{_get(spaceDetails, "meetingRoomName")}</b>
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6">
              {isOfficeSpace(spaceDetails) ? (
                <DeskBooking
                  spaceDetails={spaceDetails}
                  ref={deskBookingRef}
                  priceDiscount={discountAmount}
                  user={user}
                />
              ) : (
                <MeetingRoomBooking
                  spaceDetails={spaceDetails}
                  ref={meetingRoomBookingRef}
                  priceDiscount={discountAmount}
                  user={user}
                />
              )}
              <div
                style={{
                  background: "#fff",
                  color: "#000",
                  fontSize: "1rem",
                  padding: "10px 0",
                  marginBottom: "5px",
                  borderRadius: "5px",
                  textAlign: "left",
                }}
              >
                Use code <b>FIRST10</b> to save 10%
              </div>
              {user ? (
                <ApplyDiscount
                  spaceType={_get(spaceDetails, "spaceType")}
                  user={user}
                  buttonClick={handleDiscount}
                />
              ) : (
                ""
              )}
              {user ? (
                bookingInProgress ? (
                  //                 <div

                  //                  style={{
                  //                   border: '16px solid #f3f3f3',
                  //                   borderTop: '16px solid #2798B5',
                  //                   borderRadius: '50%',
                  //                   width: 120,
                  //                   height: 120,
                  //                   animation:
                  //                   border: 16px solid #f3f3f3; /* Light grey */
                  // border-top: 16px solid #3498db; /* Blue */
                  // border-radius: 50%;
                  // width: 120px;
                  // height: 120px;
                  // animation: spin 2s linear infinite;
                  //                  }}
                  //                 >
                  <div
                    className="d-flex justify-content-center"
                    style={{ width: "100%" }}
                  >
                    <Loader />
                  </div>
                ) : (
                  // </div>
                  <button
                    onClick={onBookNowPress}
                    className="py-2"
                    style={{
                      width: "100%",
                      backgroundColor: "#2798B5",
                      alignItems: "center",
                      border: "none",
                      borderRadius: 10,
                      color: "white",
                    }}
                  >
                    Book Now
                  </button>
                )
              ) : (
                <Link
                  to="/login/"
                  title="Login"
                  className="nav-link "
                  style={{
                    width: "100%",
                    backgroundColor: "#2798B5",
                    alignItems: "center",
                    border: "none",
                    borderRadius: 4,
                    color: "white",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Login
                </Link>
              )}
              <div className="mb-5"></div>
            </div>

            <div className="col-md-8">
              {SpaceSuitedFor !== "" ? (
                <div>
                  <h4>Space Best Suited for</h4>
                  <div style={{ paddingTop: "10px" }}>{SpaceSuitedFor}</div>
                </div>
              ) : (
                ""
              )}{" "}
              <br />
              <div>
                <div className="row amenties">
                  <div className="col-md-12">
                    <h4 id="amenities">Amenities</h4>

                    <span className="m-0 ">Space info</span>
                  </div>
                  {_get(spaceDetails, "facilitiesList").includes(
                    "Hi Speed WiFi"
                  ) ? (
                    <div className="col-md-4 col-6">
                      <div className="row aligner">
                        <div className="col-3 text-center">
                          <img
                            src="https://assets.gofloaters.com/icons/svg/hi_speed_wifi.svg"
                            alt="Hi Speed WiFi"
                            style={{
                              width: "45px",
                              border: "1px solid #2193B0",
                              padding: "10px 7px",
                              borderRadius: "5px",
                            }}
                          ></img>
                        </div>
                        <div className="col-9">
                          <p>Hi Speed WiFi</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {_get(spaceDetails, "facilitiesList").includes(
                    "Power Backup"
                  ) ? (
                    <div className="col-md-4 col-6">
                      <div className="row aligner">
                        <div className="col-3 text-center">
                          <img
                            src="https://assets.gofloaters.com/icons/svg/power_backup.svg"
                            alt="Power Backup"
                            style={{
                              width: "45px",
                              border: "1px solid #2193B0",
                              padding: "10px 7px",
                              borderRadius: "5px",
                            }}
                          ></img>
                        </div>
                        <div className="col-9">
                          <p>Power Backup</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {_get(spaceDetails, "facilitiesList").includes(
                    "WiFi Backup"
                  ) ? (
                    <div className="col-md-4 col-6">
                      <div className="row aligner">
                        <div className="col-3 text-center">
                          <img
                            src="https://assets.gofloaters.com/icons/svg/wifi_backup.svg"
                            alt="WiFi Backup"
                            style={{
                              width: "45px",
                              border: "1px solid #2193B0",
                              padding: "10px 7px",
                              borderRadius: "5px",
                            }}
                          ></img>
                        </div>
                        <div className="col-9">
                          <p> WiFi Backup</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {_get(spaceDetails, "facilitiesList").includes(
                    "Projector"
                  ) ? (
                    <div className="col-md-4 col-6">
                      <div className="row aligner">
                        <div className="col-3 text-center">
                          <img
                            src="https://assets.gofloaters.com/icons/svg/projectors.svg"
                            alt="Projector"
                            style={{
                              width: "45px",
                              border: "1px solid #2193B0",
                              padding: "10px 7px",
                              borderRadius: "5px",
                            }}
                          ></img>
                        </div>
                        <div className="col-9">
                          <p>Projector</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {_get(spaceDetails, "facilitiesList").includes(
                    "Television"
                  ) ? (
                    <div className="col-md-4 col-6">
                      <div className="row aligner">
                        <div className="col-3 text-center">
                          <img
                            src="https://assets.gofloaters.com/icons/svg/television.svg"
                            alt="Television"
                            style={{
                              width: "45px",
                              border: "1px solid #2193B0",
                              padding: "10px 7px",
                              borderRadius: "5px",
                            }}
                          ></img>
                        </div>
                        <div className="col-9">
                          <p>Television</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {_get(spaceDetails, "facilitiesList").includes(
                    "Whiteboard"
                  ) ? (
                    <div className="col-md-4 col-6">
                      <div className="row aligner">
                        <div className="col-3 text-center">
                          <img
                            src="https://assets.gofloaters.com/icons/svg/white_board.svg"
                            alt="Whiteboard"
                            style={{
                              width: "45px",
                              border: "1px solid #2193B0",
                              padding: "10px 7px",
                              borderRadius: "5px",
                            }}
                          ></img>
                        </div>
                        <div className="col-9">
                          <p>Whiteboard</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>{" "}
              <br></br>
              <p>Building Info</p>{" "}
              <div className="row amenties">
                {_get(spaceDetails, "facilitiesList").includes("AC") ? (
                  <div className="col-md-4 col-6">
                    <div className="row aligner">
                      <div className="col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/ac.svg"
                          alt="AC"
                          style={{
                            width: "45px",
                            border: "1px solid #2193B0",
                            padding: "10px 7px",
                            borderRadius: "5px",
                          }}
                        ></img>
                      </div>
                      <div className="col-9">
                        <p>AC</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {_get(spaceDetails, "facilitiesList").includes(
                  "Metro Nearby"
                ) ? (
                  <div className="col-md-4 col-6">
                    <div className="row aligner">
                      <div className="col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/metro_nearby.svg"
                          alt="Metro Nearby"
                          style={{
                            width: "45px",
                            border: "1px solid #2193B0",
                            padding: "10px 7px",
                            borderRadius: "5px",
                          }}
                        ></img>
                      </div>
                      <div className="col-9">
                        <p>Metro Nearby</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {_get(spaceDetails, "facilitiesList").includes(
                  "Free Parking"
                ) ? (
                  <div className="col-md-4 col-6">
                    <div className="row aligner">
                      <div className="col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/free_parking.svg"
                          alt="Free Parking"
                          style={{
                            width: "45px",
                            border: "1px solid #2193B0",
                            padding: "10px 7px",
                            borderRadius: "5px",
                          }}
                        ></img>
                      </div>
                      <div className="col-9">
                        <p>Free Parking</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {_get(spaceDetails, "facilitiesList").includes("Rest Rooms") ? (
                  <div className="col-md-4 col-6">
                    <div className="row aligner">
                      <div className="col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/rest_rooms.svg"
                          alt="Rest Rooms"
                          style={{
                            width: "45px",
                            border: "1px solid #2193B0",
                            padding: "10px 7px",
                            borderRadius: "5px",
                          }}
                        ></img>
                      </div>
                      <div className="col-9">
                        <p>Rest Rooms</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {_get(spaceDetails, "facilitiesList").includes("Coffee/Tea") ? (
                  <div className="col-md-4 col-6">
                    <div className="row aligner">
                      <div className="col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/coffee_tea.svg"
                          alt="Coffee/Tea"
                          style={{
                            width: "45px",
                            border: "1px solid #2193B0",
                            padding: "10px 7px",
                            borderRadius: "5px",
                          }}
                        ></img>
                      </div>
                      <div className="col-9">
                        <p>Coffee/Tea</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {_get(spaceDetails, "facilitiesList").includes("Pantry") ? (
                  <div className="col-md-4 col-6">
                    <div className="row aligner">
                      <div className="col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/pantry.svg"
                          alt="Pantry"
                          style={{
                            width: "45px",
                            border: "1px solid #2193B0",
                            padding: "10px 7px",
                            borderRadius: "5px",
                          }}
                        ></img>
                      </div>
                      <div className="col-9">
                        <p>Pantry</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {_get(spaceDetails, "facilitiesList").includes(
                  "Meeting Rooms"
                ) ? (
                  <div className="col-md-4 col-6">
                    <div className="row aligner">
                      <div className="col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/meeting_rooms.svg"
                          alt="Meeting Rooms"
                          style={{
                            width: "45px",
                            border: "1px solid #2193B0",
                            padding: "10px 7px",
                            borderRadius: "5px",
                          }}
                        ></img>
                      </div>
                      <div className="col-9">
                        <p>Meeting Rooms</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {_get(spaceDetails, "facilitiesList").includes(
                  "Outside Food Allowed"
                ) ? (
                  <div className="col-md-4 col-6">
                    <div className="row aligner">
                      <div className="col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/outside_food_allowed.svg"
                          alt="Outside Food Allowed"
                          style={{
                            width: "45px",
                            border: "1px solid #2193B0",
                            padding: "10px 7px",
                            borderRadius: "5px",
                          }}
                        ></img>
                      </div>
                      <div className="col-9">
                        <p>Outside Food Allowed</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <br />
              <div
                style={{
                  padding: "10px 15px 20px",
                  border: "1px solid #2193b0",
                  borderRadius: "10px",
                  backgroundImage:
                    "linear-gradient(rgba(234,202,175,0.49), rgba(188,175,146,.1))",
                }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <h4 id="amenities">GoFloaters Advantage</h4>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row aligner paddingTriggerTwo">
                      <div className="col-md-3 col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/lower-price.svg"
                          style={{ width: "36px" }}
                        ></img>
                      </div>
                      <div className="col-md-9 col-9">
                        <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Lowest Price Guaranteed
                        </h2>
                        {/* <p>
                            You can trust us with our fair and dependable
                            prices.
                          </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row aligner paddingTriggerTwo">
                      <div className="col-md-3 col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/free-rescheduling.svg"
                          style={{ width: "36px" }}
                        ></img>
                      </div>
                      <div className="col-md-9 col-9">
                        <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Free Rescheduling
                        </h2>
                        {/* <p>
                            You can modify the date,time and other details of
                            your scheduled booking .
                          </p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row aligner paddingTriggerTwo">
                      <div className="col-md-3 col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/free-cancellation.svg"
                          style={{ width: "36px" }}
                        ></img>
                      </div>
                      <div className="col-md-9 col-9">
                        <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                          Free Cancellation
                        </h2>
                        {/* <p>You can cancel booking upto 48 hours</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row aligner paddingTriggerTwo">
                      <div className="col-md-3 col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/instant-confirmations.png"
                          style={{ width: "36px" }}
                        ></img>
                      </div>
                      <div className="col-md-9 col-9">
                        <h2 style={{ fontWeight: "bold", fontSize: "17px" }}>
                          Instant Confirmations
                        </h2>
                        {/* <p>You can cancel booking upto 48 hours</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="row aligner paddingTriggerTwo">
                      <div className="col-md-3 col-3 text-center">
                        <img
                          src="https://assets.gofloaters.com/icons/svg/verified-listings.png"
                          style={{ width: "36px" }}
                        ></img>
                      </div>
                      <div className="col-md-9 col-9">
                        <h2 style={{ fontWeight: "bold", fontSize: "17px" }}>
                          Verified listings
                        </h2>
                        {/* <p>You can cancel booking upto 48 hours</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {_get(spaceDetails, "exclusiveOffer") ? (
                <div>
                  <br />
                  <h4>Remarks</h4> <p>{_get(spaceDetails, "exclusiveOffer")}</p>
                  <br></br>
                </div>
              ) : (
                <p> </p>
              )}
              <h4 id="opening">Opening Hours</h4>
              <b>Today ({todayDay})</b>
              <br />
              {timeCal(todayDay)}
              <br />
              <br />
              <div
                className="row"
                style={{
                  padding: "10px 5px",
                  margin: "0",
                  border: "1px solid #D9D9D9",
                }}
              >
                {operationTime}
              </div>
              <br />
              {_get(spaceDetails, "spaceDesc") ? (
                <div id="overview">
                  <h4>About this space</h4>{" "}
                  <p itemprop="description">
                    {_get(spaceDetails, "spaceDesc")}
                  </p>
                </div>
              ) : (
                ""
              )}
              {_get(spaceDetails, "location") !== "" ? (
                <SimpleMap
                  lat={_get(spaceDetails, "location")[0]}
                  lng={_get(spaceDetails, "location")[1]}
                ></SimpleMap>
              ) : (
                "<p></p>"
              )}{" "}
              <br />
              <div className="row" id="rating">
                <div className="col-md-12">
                  <br></br>
                  <h4>
                    Reviews{" "}
                    {_get(spaceDetails, "googleRating")
                      ? _get(spaceDetails, "googleRating")
                      : ""}
                  </h4>
                  <ul className="reviewTab">
                    {_get(spaceDetails, "feedback") ? (
                      <li
                        onClick={() => {
                          setReviewTab("GoFloaters")
                        }}
                        className={reviewTab === "GoFloaters" && "active"}
                      >
                        <p>
                          GoFloaters Review (
                          {Object.keys(_get(spaceDetails, "feedback"))?.length})
                        </p>
                      </li>
                    ) : (
                      ""
                    )}
                    {_get(spaceDetails, "googleReviewCount") > 0 ? (
                      <li
                        onClick={() => {
                          setReviewTab("Google")
                        }}
                        className={reviewTab === "Google" && "active"}
                      >
                        <p>
                          Google Review (
                          {_get(spaceDetails, "googleReviewCount")})
                        </p>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                  {reviewTab === "GoFloaters" &&
                  _get(spaceDetails, "feedback") ? (
                    <div>
                      <p className="customerRatingTime">
                        {Object.keys(_get(spaceDetails, "feedback"))?.length}{" "}
                        reviews
                      </p>
                      {feedbackList}
                    </div>
                  ) : (
                    <div>
                      <br />
                      <p style={{ fontSize: "18px" }}>
                        <b style={{ fontSize: "24px", color: "#1e8aa5" }}>
                          {_get(spaceDetails, "googleRating")}
                        </b>{" "}
                        Rating from{" "}
                        <b style={{ color: "#1e8aa5" }}>
                          {_get(spaceDetails, "googleReviewCount")}
                        </b>{" "}
                        reviews
                      </p>
                      <br />

                      <p>
                        {_get(spaceDetails, "googleMapsLink") ? (
                          <a
                            style={{ fontSize: "18px", color: "#1e8aa5" }}
                            href={_get(spaceDetails, "googleMapsLink")}
                            target="_blank"
                          >
                            {" "}
                            Visit Google Map Review &gt;&gt;
                          </a>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <br></br>
            </div>
            <br></br>
          </div>
        </div>
      )

    const booking = bookingDetails
    const { statusLabel, showMessage } = getBookingConfirmationStatus(booking)

    return (
      <div className="row justify-content-center">
        <div className="col-md-6 mb-5" style={{ textAlign: "center" }}>
          <p className="mb-2 bold" style={{ color: COLORS.PRIMARY_COLOR }}>
            {statusLabel}
          </p>
          <p className="bold">{_get(booking, "bookingid")}</p>
          <div
            className="d-flex justify-content-center"
            style={{ position: "relative" }}
          >
            <div
              className="animation-container"
              style={{ width: 200, height: 200 }}
              ref={animationContainer}
            />
            <h2
              className="bold"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#2798B5",
              }}
            >
              Yay!
            </h2>
          </div>

          <div
            className="py-2 px-2 mb-5"
            style={{ border: "1px dotted #2798B5", borderRadius: 10 }}
          >
            <div className="d-flex flex-row align-items-center">
              <p className="bold">Booking Id:&nbsp; </p>
              <p>{_get(booking, "bookingid")}</p>
            </div>
            <div className="d-flex flex-row align-items-center">
              <p className="bold">Booking For:&nbsp; </p>
              <p>{_get(booking, "spaceName")}</p>
            </div>
            <div className="d-flex flex-row align-items-center">
              <p className="bold">Booking Date:&nbsp; </p>
              <p>
                {moment(_get(booking, "startTime")).format(
                  "Do MMMM YYYY, hh:mma"
                )}
              </p>
            </div>
          </div>

          <div
            className="d-flex flex-row align-items-center mb-2"
            style={{ columnGap: 16 }}
          >
            <div style={{ flex: 1 }}>
              <Link
                to="/bookings/"
                className="nav-link"
                style={{
                  width: "100%",
                  backgroundColor: "#2798B5",
                  alignItems: "center",
                  border: "none",
                  borderRadius: 4,
                  color: "white",
                }}
              >
                Track Booking
              </Link>
              {/* <button
                onClick={onBookNowPress}
                className="py-2"
                style={{
                  width: "100%",
                  backgroundColor: "#2798B5",
                  alignItems: "center",
                  border: "none",
                  borderRadius: 4,
                  color: "white",
                }}
              >
                Track Booking
              </button> */}
            </div>

            {/* <div style={{ flex: 1 }}>
              <button
                onClick={onBookNowPress}
                className="py-2"
                style={{
                  width: "100%",
                  backgroundColor: "#2798B5",
                  alignItems: "center",
                  border: "none",
                  borderRadius: 4,
                  color: "white",
                }}
              >
                Share
              </button>
            </div> */}
          </div>
          {showMessage ? (
            <p>
              You will receive an SMS and email confirmation within 30 mins if
              the space is available.
            </p>
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <div>
      <SEOHeader
        title={"Book Spaces | GoFloaters"}
        description={"Book Spaces | GoFloaters"}
      ></SEOHeader>

      <Layout type="meetingSpace">
        <div
          itemscope
          itemtype="https://schema.org/LocalBusiness"
          className="container newspaceDetailContainer"
        >
          {getContentView()}
        </div>
      </Layout>
    </div>
    // <Bookings />
  )
}

export default Booking
